import { once, isEmpty } from 'lodash-es'

export const useZenDesk = () => {
  const isZendeskOpened = useState('useToggleZendesk', () => false)
  const onZendesk = (value: boolean) => {
    if (window.zE) {
      const open = value ?? !unref(isZendeskOpened)
      if (open === unref(isZendeskOpened)) {
        return
      } else if (open) {
        window.zE('webWidget', 'open')
        window.zE('webWidget', 'show')
      } else {
        window.zE('webWidget', 'hide')
        window.zE('webWidget', 'close')
      }
      isZendeskOpened.value = open
    }
  }
  const bindOnce = once((callback) => {
    window.zE?.('webWidget:on', 'close', () => {
      window.zE?.('webWidget', 'hide')
      callback()
    })
  })
  return {
    onToggle: () => {
      if (window.zE as any) {
        bindOnce(() => {
          onZendesk(false)
        })
        onZendesk(!isZendeskOpened.value)
      }
    },
  }
}
